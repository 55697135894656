import i18n from '@/i18n'
import { mapState } from 'vuex'
import Lset from 'lodash/set'
import Lmerge from 'lodash/merge'

import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import apiClient from '@/lib/unlogin/store/apiclient'
import { createNotification } from '@/lib/unnotificationsqueue'

import Layout from '@/apps/core/components/Layout'
import sections from '../defaultNavigationDrawerSections'
import { flattenFormProps } from '@/apps/core/helpers/utils'

export default {
  name: 'PromoDetachmentsAssignationList',
  components: {
    Layout,
    CrudTable
  },
  data () {
    return {
      title: i18n.tc('PromoDetachmentsAssignation.sectionName'),
      sections: sections,
      customOptions: { 'sortBy': ['id'], 'sortDesc': [true] },
      storeModule: 'promoDetachmentAssignations',
      descriptionValue: 'topic_name',
      paramToSend: {
        note: null,
        status: null,
        allocated: null,
        promo_detachment: null
      },
      actionList: {
        create: i18n.tc('PromoDetachmentsAssignation.createNoteLine'),
        close: i18n.tc('PromoDetachmentsAssignation.createNoteLineAndClosePromo'),
        reject: i18n.tc('PromoDetachmentsAssignation.rejectNoteLine')
      }
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig },
      promoDetachmentLoading: function (state) { return state[this.storeModule].loading },
      dynamicDialogSelectedItems: function (state) { return state[this.storeModule].dynamicDialogSelectedItems },
      noteLineStatus: function (state) { return state[this.storeModule].noteLineStatus }
    }),
    linkAttributes () {
      const linkAttributes = {}
      linkAttributes['path'] = `item.name`
      linkAttributes['name'] = 'name'
      return linkAttributes
    }
  },
  methods: {
    Lset,
    /**
   * Event handler when modifying a CrudTable's editable field. Redirects it to respective patch endpoint
   * @param value {string, number} new value
   * @param header {string} field edited
   * @param item {object} full instance of modified row
   * @returns {Promise<void>}
   */
    async onModifyField ({ value, header, item }) {
      let previousValue = this.Lget(item, header)
      let nestedField = header.split('.')
      try {
        if (nestedField.length === 1 || (nestedField.length === 2 && nestedField[0] === 'props')) {
          await this.onModifyNoteLine(header, value, item)
        } else if (nestedField.length > 1) {
          let model = nestedField[0]
          let attribute = nestedField.slice(1).join('.')
          await this.onModifyNested(attribute, value, model, item)
        }
      } catch (error) {
        this.Lset(item, header, previousValue)
      }
    },

    /**
   * Set the values onChanged items
   * @param header
   * @param value
   * @param item
 //   */
    async setValueToSend ({ header, value, item }) {
      let body = flattenFormProps(Lmerge(item, Lset({}, header, value)))
      this.paramToSend.note = body.id
      this.paramToSend.allocated = body.note_line_allocated
      this.paramToSend.status = body.note_line_status
      this.paramToSend.promo_detachment = body.note_line_promo_detachment
    },

    /**
   * Called when a nested element is modified (So far only Note): calls element's patch endpoint
   * @param attribute {string} parsed from header, refers to the models's modified attribute
   * @param value same as onModifyField
   * @param model {string} parsed from header, refers to the model whose attribute was modified (So far only note)
   * @param item same as onModifyField
   * @returns {Promise<void>}
   */
    async onModifyNested (attribute, value, model, item) {
      try {
        let instanceId = item[model].id
        let body = flattenFormProps(Lmerge(item[model], Lset({}, attribute, value)))
        body.props = item.note.props
        await apiClient.patch(`/${model}s/${instanceId}/`, body)
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
      } catch (error) {
        throw error
      }
    },

    formWarningMessage () {
      this.$store.dispatch('addNotification', createNotification(this.$t('dialogs.formWarning'), 'warning'))
    },

    async onRejectNoteLine (item) {
      if (item.value === null || item.value === undefined) {
        this.formWarningMessage()
        return
      }

      if (await this.$store.dispatch(`${this.storeModule}/rejectNoteLine`, item)) {
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
        await this.$store.commit(`${this.storeModule}/cleanEditableValues`, item.id)
      }
    },

    async onCreateNoteLine (item) {
      if (!this.canSendRequest(item)) {
        this.formWarningMessage()
        return
      }

      if (await this.$store.dispatch(`${this.storeModule}/createNoteLine`, item)) {
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
        await this.$store.commit(`${this.storeModule}/cleanEditableValues`, item.id)
      }
    },

    async onCloseNote (item) {
      if (!this.canSendRequest(item)) {
        this.formWarningMessage()
        return
      }
      if (!this.canCloseDetachment(item)) {
        let msg = "This action can't be completed unless the Payment Line Status is 'Approved'."
        await this.$store.dispatch('addNotification', createNotification(msg, 'warning'))
        return
      }

      if (await this.$store.dispatch(`${this.storeModule}/createNoteLineCloseDetachment`, item)) {
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
        await this.$store.commit(`${this.storeModule}/cleanEditableValues`, item.id)
      }
    },

    canSendRequest (item) {
      const isStatusSet = !(item.note_line_status === null || item.note_line_status === undefined)
      const isPromoDetachmentSet = ((el) => {
        return el !== undefined && el !== null
      })(this.dynamicDialogSelectedItems.find(el => el.parentId === item.id))
      return isStatusSet && isPromoDetachmentSet
    },

    canCloseDetachment (item) {
      const PNG_APPROVED_NOTE_LINE_STATUS = this.noteLineStatus.find(el => el.value === 'Approved').key
      return item.note_line_status === PNG_APPROVED_NOTE_LINE_STATUS
    }
  }
}
