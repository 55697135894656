import { render, staticRenderFns } from "./PromoDetachmentsAssignationList.html?vue&type=template&id=3921909e&scoped=true&"
import script from "./PromoDetachmentsAssignationList.component.js?vue&type=script&lang=js&"
export * from "./PromoDetachmentsAssignationList.component.js?vue&type=script&lang=js&"
import style0 from "./PromoDetachmentsAssignationList.scss?vue&type=style&index=0&id=3921909e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3921909e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
