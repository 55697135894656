var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"top-bar-title":_vm.title,"sections":_vm.sections,"baseLayoutInfoUrl":'/base-layout-info/',"displayAllScreen":""}},[_c('CrudTable',_vm._b({attrs:{"storeModule":_vm.storeModule,"loading":_vm.promoDetachmentLoading,"customOptions":_vm.customOptions,"addItems":false,"adaptableHeight":true,"fetch-editable-options":"","softLoading":""},on:{"save":_vm.setValueToSend},scopedSlots:_vm._u([{key:_vm.linkAttributes.path,fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'NoteDetailView', params: { id: item.id }}}},[_vm._v(" "+_vm._s(_vm.Lget(item, _vm.linkAttributes.name, '-') || '-')+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-10",attrs:{"color":"blue"},on:{"click":function($event){return _vm.onCloseNote(item)}}},on),[_vm._v("fa-door-closed")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.actionList.close))])]),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-10",attrs:{"color":"green"},on:{"click":function($event){return _vm.onCreateNoteLine(item)}}},on),[_vm._v("fa-check")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.actionList.create))])]),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":"red"},on:{"click":function($event){return _vm.onRejectNoteLine(item)}}},on),[_vm._v("fa-times")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.actionList.reject))])])]}}],null,true)},'CrudTable',_vm.crudTableConfig,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }